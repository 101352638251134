<template>
  <div class="single-arrow-wrapper">
    <div
      v-for="(item,index) in menus"
      :key="item.id"
      :class="['item-wrapper', 'h2',currentMenuIndex==index?'light-blue':'']"
      @click="selectMenu(item,index)"
      @mouseenter="mouseenter(item,index)"
    >
      <div class="title">{{item.name}}</div>
      <img v-if="currentMenuIndex==index" src="@/static/images/single-arrow.png" class="arrow" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['menus', 'preMenuId'],
  data() {
    return {
      currentMenuId: null,
    }
  },
  computed: {
    currentMenuIndex() {
      for (var i = 0; i < this.menus.length; i++) {
        if (this.menus[i]['id'] == this.currentMenuId) {
          return i
        }
      }
      return 0

    },
  },
  created() {
    if (this.preMenuId) {
      this.currentMenuId = this.preMenuId
    }
  },
  mounted() {

  },
  methods: {
    selectMenu(item, index) {
      this.currentMenuId = item.id
      this.$emit('selectMenu', item.id)
    },
    mouseenter(item, index) {
      if (item.id != 'ruzhushenqing') {
        this.selectMenu(item, index)
      }
    },
  }
}
</script>

<style scoped lang="less">
.single-arrow-wrapper {
  padding: 0 20px;

  .item-wrapper {
    width: 100%;
    height: 40px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: 10px 0;

    .title {
      flex: 1;
    }

    .arrow {
      width: 60px;
      height: 15px;
    }
  }
}
</style>
