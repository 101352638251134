<template>
  <div class="cell-wrapper">
    <div style="width:30%;display: flex;align-items: flex-start;cursor: pointer;flex-shrink: 0;" @click="handleClick">
      <div>{{ info.companyName }}</div>
      <div class="zheng">证</div>
    </div>
    <div class="title h2" style="margin-left:50px;width: 150px;overflow: hidden;flex-shrink: 0;">{{ info.positionName }}
    </div>
    <!-- <div style="width: 1px;height: 100%;background-color: #0087ff;margin: 0 20px;"></div> -->
    <div class="meet" style="width: 110px;flex-shrink: 0;">{{ salary }}</div>
    <div class="tag">{{ info.base }} | {{ education }} | {{ experience }}</div>
  </div>
</template>

<script>
export default {
  props: ['info', 'hideDetailButton'],
  data() {
    return {}
  },
  computed: {
    salary() {
      const v = this.info?.salary
      if (v == '1') {
        return '面议'
      }
      if (v == '2') {
        return '3000-6000'
      }
      if (v == '3') {
        return '6000-10000'
      }
      if (v == '4') {
        return '10000-20000'
      }
      return '--'
    },
    education() {
      const v = this.info?.education
      if (v == '1') {
        return '学历不限'
      }
      if (v == '2') {
        return '专科及以上'
      }
      if (v == '3') {
        return '本科及以上'
      }
      if (v == '4') {
        return '硕士及以上'
      }
      if (v == '5') {
        return '博士'
      }
      return '--'
    },
    experience() {
      const v = this.info?.experience
      if (v == '1') {
        return '经验不限'
      }
      if (v == '2') {
        return '3年以内'
      }
      if (v == '3') {
        return '3-5年'
      }
      if (v == '4') {
        return '5年以上'
      }
      return '--'
    },
    positionIndustry() {
      const v = this.info?.positionIndustry
      if (v == '1') {
        return '不限'
      }
      if (v == '2') {
        return '计算机/互联网/通信'
      }
      if (v == '3') {
        return '市场/公关'
      }
      if (v == '4') {
        return '销售'
      }
      if (v == '5') {
        return '美术/设计/创意'
      }
      if (v == '6') {
        return '汽车行业'
      }
      if (v == '7') {
        return '机械/仪器仪表'
      }
      if (v == '8') {
        return '食品饮料'
      }
      if (v == '9') {
        return '其他'
      }
      return '--'
    },
  },
  created() { },
  mounted() {

  },
  methods: {
    handleClick() {
      this.$emit('handleClick', this.info)
    },
  }
}
</script>

<style scoped lang="less">
.cell-wrapper {
  height: 60px;
  display: flex;

  .zheng {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background-color: #00aa00;
    font-size: 16px;
    color: #fff;
    margin: 2px 0 0 2px;
  }

  .meet {
    color: #d91d40;
    margin-right: 20px;
  }

  .tag {
    padding-right: 10px;
  }
}
</style>
