<template>
  <div
    style="display: flex;flex-direction: column;align-items: center;flex: 1;margin: 0 20px;cursor: pointer;min-width: 50px;max-width: 32%;"
    @click="handleClick"
  >
    <img :src="pictureUrl" class="ruzhu-img" :style="picStyle" />
    <div
      class="h2 single-line"
      style="min-height: 30px;margin: 10px 0;text-align: center;text-overflow: ellipsis;overflow: hidden;width: 100%;"
    >{{title}}</div>
  </div>
</template>

<script>
export default {
  props: ['info', 'picType', 'picHeight'],
  data() {
    return {}
  },
  computed: {
    pictureUrl() {
      if (this.picType == 'index' && this.info.indexPicUrl) {
        return this.info.indexPicUrl
      }
      if (this.picType == 'list' && this.info.itemPicUrl) {
        return this.info.itemPicUrl
      }
      if (this.info?.pictureUrl) {
        return this.info.pictureUrl
      }
      if (this.info?.images) {
        return this.info.images[0]
      }
      else {
        return ''
      }
    },
    title() {
      if (this.info?.title) {
        return this.info.title
      }
      if (this.info?.institutesName) {
        return this.info.institutesName
      }
      if (this.info?.enterpriseName) {
        return this.info.enterpriseName
      }
      if (this.info?.parkName) {
        return this.info.parkName
      }
      if (this.info?.buildingName) {
        return this.info.buildingName
      }
      if (this.info?.gizName) {
        return this.info.gizName
      }
      if (this.info?.activityName) {
        return this.info.activityName
      }
      if (this.info?.pictureName) {
        return this.info.pictureName
      }
      return ''
    },
    content() {
      var c = ''
      if (this.info?.content) {
        c = this.info.content
      }
      if (this.info?.institutesDetail) {
        c = this.info.institutesDetail
      }
      if (this.info?.enterpriseIntroduction) {
        c = this.info.enterpriseIntroduction
      }
      return c
    },
    picStyle() {
      if (this.picHeight) {
        return {
          'height': `${this.picHeight}px`
        }
      }
      else {
        return {}
      }
    },
  },
  created() { },
  mounted() {
  },
  methods: {
    handleClick() {
      this.$emit('handleClick', this.info)
    },
  }
}
</script>

<style scoped lang="less">
.ruzhu-img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}
</style>
