<template>
  <div class="body-content">
    <banner v-if="bannerList && bannerList.length > 0" :homeBanner="bannerList"></banner>
    <div class="horizontal_1_1_wrapper">
      <div class="item">
        <section-title title="通知公告" :showMore="true" @moreClick="goTzgg()"></section-title>
        <div class="bg-rgba-w-1" style="padding: 10px;height: 240px;">
          <point-title v-for="item in announcementList" :title="item.title"
            :time="item.time ? item.time.substring(0, 10) : '--'" @goDetail="goTzgg(item.id)"
            :key="item.title"></point-title>
          <!-- <div
            v-for="item in announcementList"
            :key="item.id"
            style="display: flex;height: 60px;align-items: stretch;padding: 10px 0;cursor: pointer;"
            @click="goTzgg(item.id)"
          >
            <div
              style="width: 80px;height: 100%;text-align: center;display: flex;flex-direction: column;justify-content: space-between"
            >
              <div
                style="font-size: 200%;font-weight: 700;"
              >{{ item.time ? item.time.substring(8, 11) : '--' }}</div>
              <div class="h2">{{ item.time ? item.time.substring(0, 7) : '--' }}</div>
            </div>
            <div style="width: 3px;height: 100%;background-color: #fff;margin: 0 20px;"></div>
            <div
              style="display: flex;flex-direction: column;justify-content: space-between;flex: 1;overflow: hidden;"
            >
              <div class="h2 single-line">{{ item.title }}</div>
              <div class="h4 single-line">主办单位:中国(绵阳) 科技城管理委员会中共绵阳市委</div>
            </div>
          </div>-->
        </div>
      </div>
      <div class="divider-h"></div>
      <div class="item">
        <section-title title="快速检索"></section-title>
        <div class="bg-rgba-w-1"
          style="display: flex;flex-direction: column;justify-content: space-around;height: 260px;padding: 0 20px;">
          <el-select v-model="class1" placeholder="-招院引所  /  招商引资  /  招才引智 /  院士（专家）小镇  /  基金小镇- "
            @change="class1Change">
            <el-option v-for="item in class1_options" :key="item.label" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
          <el-select v-model="class2" :placeholder="class1 ? '请选择' : '请先选择上一行菜单'">
            <el-option v-for="item in class2_options" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <div class="h2 custom-yellow-button" @click="checkChildren">查询</div>
          <div style="display: flex;">
            <!-- <div
              class="h2 custom-yellow-button"
              style="flex: 1;min-width: 10px;margin-right: 10px;"
              @click="topHuodongBaoming"
            >活动报名</div> -->
            <div class="h2 custom-yellow-button" style="flex: 1;min-width: 10px;" @click="topRuzhuShenqing"
              v-if="class1 != '2'">入驻申请</div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <section-title title="招院引所"></section-title>
    <div class="horizontal_1_3_wrapper bg-rgba-w-1" style="height:230px;">
      <div class="left">
        <single-arrow-menulist :menus="menus1" @selectMenu="selectZyysMenu"></single-arrow-menulist>
      </div>
      <div class="divider-h"></div>
      <div class="right">
        <div v-if="menu1_id == 'ruzhuyuansuo'">
          <div class="sub-section-title">
            <div class="h3">入驻院所</div>
            <div class="more h3" @click="selectZyysMore()">更多>></div>
          </div>
          <div class="content">
            <cell-index-ruzhuqiye v-for="item in institutesMap.institutes" :key="item.id" :info="item" picType="index"
              picHeight="150" @handleClick="(v) => { detailZyys(v, 'ruzhuyuansuo') }"></cell-index-ruzhuqiye>
          </div>
        </div>
        <div v-if="menu1_id == 'zhaoyinzhengce'">
          <div class="sub-section-title">
            <div class="h3">招引政策</div>
            <div class="more h3" @click="selectZyysMore()">更多>></div>
          </div>
          <div>
            <point-title v-for="item in institutesMap.institutesPolicy" :key="item.id" :title="item.title"
              :time="item.time ? item.time.substring(0, 10) : '--'"
              @goDetail="() => { detailZyys(item, 'zhaoyinzhengce') }"></point-title>
          </div>
        </div>
        <div v-if="menu1_id == 'zhaoyinfuwu'">
          <div class="sub-section-title">
            <div class="h3">招引服务</div>
            <div class="more h3" @click="selectZyysMore()">更多>></div>
          </div>
          <div style="width: 90%;margin: 0 auto;">
            <img src="../../static/images/cloud/zhaoyinfuwu.png"
              style="width: 100%;height: auto;object-fit: contain;" />
          </div>
        </div>
      </div>
    </div>
    <section-title title="招商引资"></section-title>
    <div class="horizontal_1_3_wrapper bg-rgba-w-1" style="height:380px;">
      <div class="left">
        <single-arrow-menulist :menus="menus2" @selectMenu="selectZsyzMenu"></single-arrow-menulist>
      </div>
      <div class="divider-h"></div>
      <div class="right">
        <div v-if="menu2_id == 'ruzhuqiye'">
          <div class="sub-section-title">
            <div class="h3">入驻企业</div>
            <div class="more h3" @click="selectZsyzMore()">更多>></div>
          </div>
          <div class="content">
            <cell-index-ruzhuqiye v-for="item in businessMap.enterprise" :key="item.id" :info="item" picType="index"
              @handleClick="(v) => { detailZsyz(v, 'ruzhuqiye') }"></cell-index-ruzhuqiye>
          </div>
        </div>
        <div v-if="menu2_id == 'yuanquxinxi'">
          <div class="sub-section-title">
            <div class="h3">园区信息</div>
            <div class="more h3" @click="selectZsyzMore()">更多>></div>
          </div>
          <div class="content">
            <cell-index-ruzhuqiye v-for="item in businessMap.park" :key="item.id" :info="item" picType="index"
              @handleClick="(v) => { detailZsyz(v, 'yuanquxinxi') }"></cell-index-ruzhuqiye>
          </div>
        </div>
        <div v-if="menu2_id == 'changfangzushou'">
          <div class="sub-section-title">
            <div class="h3">厂房租售</div>
            <div class="more h3" @click="selectZsyzMore()">更多>></div>
          </div>
          <div class="content">
            <cell-index-ruzhuqiye v-for="item in businessMap.workshop" :key="item.id" :info="item" picType="index"
              @handleClick="(v) => { detailZsyz(v, 'changfangzushou') }"></cell-index-ruzhuqiye>
          </div>
        </div>
        <div v-if="menu2_id == 'louyuzushou'">
          <div class="sub-section-title">
            <div class="h3">楼宇租售</div>
            <div class="more h3" @click="selectZsyzMore()">更多>></div>
          </div>
          <div class="content">
            <cell-index-ruzhuqiye v-for="item in businessMap.building" :key="item.id" :info="item" picType="index"
              @handleClick="(v) => { detailZsyz(v, 'louyuzushou') }"></cell-index-ruzhuqiye>
          </div>
        </div>
        <div v-if="menu2_id == 'zhaoyinanli'">
          <div class="sub-section-title">
            <div class="h3">招引案例</div>
            <div class="more h3" @click="selectZsyzMore()">更多>></div>
          </div>
          <div class="content">
            <cell-index-ruzhuqiye v-for="item in businessMap.example" :key="item.id" :info="item" picType="index"
              @handleClick="(v) => { detailZsyz(v, 'zhaoyinanli') }"></cell-index-ruzhuqiye>
          </div>
        </div>
        <div v-if="menu2_id == 'zhaoyinzhengce'">
          <div class="sub-section-title">
            <div class="h3">招引政策</div>
            <div class="more h3" @click="selectZsyzMore()">更多>></div>
          </div>
          <div>
            <point-title v-for="item in businessMap.businessPolicy" :key="item.id" :title="item.title"
              :time="item.time ? item.time.substring(0, 10) : '--'"
              @goDetail="() => { detailZsyz(item, 'zhaoyinzhengce') }"></point-title>
          </div>
        </div>
      </div>
    </div>
    <section-title title="招才引智"></section-title>
    <div class="horizontal_1_3_wrapper bg-rgba-w-1" style="height:240px;">
      <div class="left">
        <div style="height:240px;display:flex;flex-direction: column;justify-content: center;">
          <single-arrow-menulist :menus="menus3" @selectMenu="selectZcyzMenu"></single-arrow-menulist>
        </div>
      </div>
      <div class="divider-h"></div>
      <div class="right">
        <div v-if="menu3_id == 'zhengcezhichi'">
          <div class="sub-section-title">
            <div class="h3">招才政策</div>
            <div class="more h3" @click="selectZcyzMore">更多>></div>
          </div>
          <div style="display: flex;flex-direction: column;">
            <point-title v-for="item in talentMap.talentPolicy" :key="item.id" :title="item.title"
              :time="item.time ? item.time.substring(0, 10) : '--'"
              @goDetail="() => { detailZcyz(item, 'zhengcezhichi') }"></point-title>
          </div>
        </div>
        <div v-if="menu3_id == 'rencaizhaopin'">
          <div class="sub-section-title">
            <div class="h3">人才招聘</div>
            <div class="more h3" @click="selectZcyzMore">更多>></div>
          </div>
          <div>
            <cell-rencaizhaopin v-for="p in talentMap.recruit.slice(0, 3)" :key="p.id"
              @handleClick="() => { detailZcyz(p, 'rencaizhaopin') }" :info="p"></cell-rencaizhaopin>
          </div>
        </div>
      </div>
    </div>
    <!-- <section-title title="院士专家小镇"></section-title>
    <div class="horizontal_1_3_wrapper bg-rgba-w-1" style="height:280px;">
      <div class="left">
        <div style="height:280px;display: flex;flex-direction: column;justify-content: center;">
          <single-arrow-menulist
            :menus="menus4"
            @selectMenu="selectYsxzMenu"
            preMenuId="xiaozhenzhanshi"
          ></single-arrow-menulist>
        </div>
      </div>
      <div class="divider-h"></div>
      <div class="right">
        <div v-if="menu4_id == 'xiaozhenzhanshi'">
          <div class="sub-section-title">
            <div class="h3">小镇展示</div>
            <div class="more h3" @click="selectYsxzMore()">更多>></div>
          </div>
          <div class="content">
            <cell-index-ruzhuqiye
              v-for="item in expertTownMap.expertTown"
              :key="item.id"
              :info="item"
              picType="index"
              picHeight="180"
              @handleClick="selectYsxzMore()"
            ></cell-index-ruzhuqiye>
          </div>
        </div>
        <div v-if="menu4_id == 'yuanshijieshao'">
          <div class="sub-section-title">
            <div class="h3">院士(专家)小镇</div>
            <div class="more h3" @click="selectYsxzMore()">更多>></div>
          </div>
          <div class="content">
            <div
              v-for="item in expertTownMap.expert"
              :key="item.id"
              style="display: flex;flex-direction: column;align-items: stretch;flex: 1;margin: 0 20px;cursor: pointer;min-width: 50px;"
              @click="yuanshiDetail(item)"
            >
              <img :src="item.photoUrl" style="width: 100%;height: 150px;object-fit: cover;" />
              <div
                class="h2 single-line"
                style="min-height: 20px;margin-top: 10px;text-align: center;"
              >{{ item.expertName }}</div>
              <div
                class="h4 single-line"
                style="min-height: 20px;margin-top: 10px;text-align: center;"
              >{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div v-if="menu4_id == 'yuanshijiangtang'">
          <div class="sub-section-title">
            <div class="h3">院士(专家)小镇</div>
            <div class="more h3" @click="selectYsxzMore()">更多>></div>
          </div>
          <div class="content">
            <cell-index-ruzhuqiye
              v-for="item in expertTownMap.forum.slice(0, 3)"
              :key="item.id"
              :info="item"
              picType="index"
              picHeight="180"
              @handleClick="(v) => { detailYsxz(v, 'yuanshijiangtang') }"
            ></cell-index-ruzhuqiye>
          </div>
        </div>
        <div v-if="menu4_id == 'zhengcezhichi'">
          <div class="sub-section-title">
            <div class="h3">政策支持</div>
            <div class="more h3" @click="selectYsxzMore()">更多>></div>
          </div>
          <div>
            <point-title
              v-for="item in expertTownMap.expertPolicy"
              :key="item.id"
              :title="item.title"
              :time="item.time ? item.time.substring(0, 10) : '--'"
              @goDetail="() => { detailYsxz(item, 'zhengcezhichi') }"
            ></point-title>
          </div>
        </div>
      </div>
    </div>-->
    <section-title title="基金小镇"></section-title>
    <div class="horizontal_1_3_wrapper bg-rgba-w-1" style="height:300px;">
      <div class="left">
        <single-arrow-menulist :menus="menus5" @selectMenu="selectJjxzMenu"></single-arrow-menulist>
      </div>
      <div class="divider-h"></div>
      <div class="right">
        <div v-if="menu5_id == 'xiaozhenzhanshi'">
          <div class="sub-section-title">
            <div class="h3">小镇展示</div>
            <div class="more h3" @click="selectJjxzMore">更多>></div>
          </div>
          <div style="display: flex;overflow: hidden;height: 260px;">
            <img :src="fundTownMap.fundTown[0]['pictureUrl']" style="width: 440px;object-fit: cover;height: 260px;" />

            <div>
              <div class="xiaozhen-title">{{ fundTownMap.fundTown[0]['introductionTitle'] }}</div>
              <div class="xiaozhen-text">{{ fundTownMap.fundTown[0]['introduction'] }}</div>
            </div>
          </div>
        </div>
        <div v-if="menu5_id == 'jinrongjigou'">
          <div class="sub-section-title">
            <div class="h3">金融机构</div>
            <div class="more h3" @click="selectJjxzMore">更多>></div>
          </div>
          <div class="content">
            <cell-index-ruzhuqiye v-for="item in fundTownMap.giz" :key="item.id" :info="item" picType="index"
              picHeight="200" @handleClick="(v) => { detailJjxz(v, 'jinrongjigou') }"></cell-index-ruzhuqiye>
          </div>
        </div>
        <div v-if="menu5_id == 'luyanhuodong'">
          <div class="sub-section-title">
            <div class="h3">路演活动</div>
            <div class="more h3" @click="selectJjxzMore">更多>></div>
          </div>
          <div class="content">
            <cell-index-ruzhuqiye v-for="item in fundTownMap.activity.slice(0, 3)" :key="item.id" :info="item"
              picType="index" picHeight="200"
              @handleClick="(v) => { detailJjxz(v, 'luyanhuodong') }"></cell-index-ruzhuqiye>
          </div>
        </div>
        <div v-if="menu5_id == 'zhengcezhichi'">
          <div class="sub-section-title">
            <div class="h3">政策支持</div>
            <div class="more h3" @click="selectJjxzMore">更多>></div>
          </div>
          <div>
            <point-title v-for="item in fundTownMap.fundPolicy" :key="item.id" :title="item.title"
              :time="item.time ? item.time.substring(0, 10) : '--'"
              @goDetail="() => { detailJjxz(item, 'zhengcezhichi') }"></point-title>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '../../components/banner.vue';
import sectionTitle from '@/views/policyHall/components/section-title.vue'
import singleArrowMenulist from './components/single-arrow-menulist.vue'
import pointTitle from '@/components/point-article-title.vue'
import cellIndexRuzhuqiye from './components/cell-index-ruzhuqiye.vue'
import cellRencaizhaopin from './components/cell-index-rencaizhaopin.vue'

export default {
  name: 'yszy-index',
  components: {
    Banner,
    sectionTitle,
    singleArrowMenulist,
    pointTitle,
    cellIndexRuzhuqiye,
    cellRencaizhaopin
  },
  data() {
    return {
      pageTopPosition: 0,
      bannerList: [],
      class1: null,
      class2: null,
      menu1_id: 'ruzhuyuansuo',
      menus1: [{
        'name': '入驻院所',
        'id': 'ruzhuyuansuo'
      },
      {
        'name': '招引政策',
        'id': 'zhaoyinzhengce'
      },
      {
        'name': '招引服务',
        'id': 'zhaoyinfuwu'
      },
      {
        'name': '入驻申请',
        'id': 'ruzhushenqing'
      }
      ],
      menu2_id: 'ruzhuqiye',
      menus2: [{
        'name': '入驻企业',
        'id': 'ruzhuqiye'
      },
      {
        'name': '园区信息',
        'id': 'yuanquxinxi'
      },
      {
        'name': '厂房租售',
        'id': 'changfangzushou'
      },
      {
        'name': '楼宇租售',
        'id': 'louyuzushou'
      },
      {
        'name': '招引案例',
        'id': 'zhaoyinanli'
      },
      {
        'name': '招引政策',
        'id': 'zhaoyinzhengce'
      },
      {
        'name': '入驻申请',
        'id': 'ruzhushenqing'
      }
      ],
      menu3_id: 'zhengcezhichi',
      menus3: [{
        'name': '招才政策',
        'id': 'zhengcezhichi'
      },
      {
        'name': '人才招聘',
        'id': 'rencaizhaopin'
      }
      ],
      menu4_id: 'xiaozhenzhanshi',
      menus4: [{
        'name': '小镇展示',
        'id': 'xiaozhenzhanshi'
      },
      // {
      //   'name': '院士（专家）介绍',
      //   'id': 'yuanshijieshao'
      // },
      // {
      //   'name': '院士大讲堂',
      //   'id': 'yuanshijiangtang'
      // },
      {
        'name': '政策支持',
        'id': 'zhengcezhichi'
      },
      {
        'name': '入驻申请',
        'id': 'ruzhushenqing'
      }
      ],
      menu5_id: 'xiaozhenzhanshi',
      menus5: [{
        'name': '小镇展示',
        'id': 'xiaozhenzhanshi'
      },
      {
        'name': '金融机构',
        'id': 'jinrongjigou'
      },
      {
        'name': '路演活动',
        'id': 'luyanhuodong'
      },
      {
        'name': '政策支持',
        'id': 'zhengcezhichi'
      },
      {
        'name': '入驻申请',
        'id': 'ruzhushenqing'
      }
      ],
      announcementList: [],
      //招院引所
      institutesMap: {
        'institutes': [],
        'institutesPolicy': [],
      },
      //招商引资
      businessMap: {
        'building': [],
        'businessPolicy': [],
        'enterprise': [],
        'example': [],
        'park': [],
        'workshop': [],
      },
      //院士专家小镇
      expertTownMap: {
        'expert': [],
        'expertPolicy': [],
        'expertTown': [],
        'forum': [],
      },
      //基金小镇
      fundTownMap: {
        'fundTown': [
          {}
        ],
        'activity': [],
        'fundPolicy': [],
        'giz': [],
      },
      //招才引智
      talentMap: {
        'recruit': [],
        'talentPolicy': [],
      },

    }
  },
  computed: {
    class1_options() {
      return [{
        'label': '招院引所',
        'value': 0,
        'children': this.menus1
      },
      {
        'label': '招商引资',
        'value': 1,
        'children': this.menus2
      },
      {
        'label': '招才引智',
        'value': 2,
        'children': this.menus3
      },
      // {
      //   'label': '院士（专家）小镇',
      //   'value': 3,
      //   'children': this.menus4
      // },
      {
        'label': '基金小镇',
        'value': 3,
        'children': this.menus5
      }
      ];
    },
    class2_options() {
      if (this.class1 !== null) {
        return this.class1_options[this.class1]['children']
      }
      return []
    },
  },
  beforeRouteLeave(to, from, next) {
    this.pageTopPosition = document.getElementById('app').scrollTop
    next()
  },
  activated() {
    document.getElementById('app').scrollTop = this.pageTopPosition
  },
  created() {
    // 调用工具方法示例
    console.log(this.$util.testName());
  },
  mounted() {
    this.getIndexInfo()
  },
  methods: {
    class1Change() {
      this.class2 = null
    },
    checkChildren() {
      if (this.class2 == null) {
        this.$message.warning('请选择查询的分类');
        return
      }
      if (this.class1 == 0) {
        this.$router.push('/solicitationCloud/page-zyys?menu_id=' + this.class2)
      } else if (this.class1 == 1) {
        this.$router.push('/solicitationCloud/page-zsyz?menu_id=' + this.class2)
      }
      else if (this.class1 == 2) {
        this.$router.push('/solicitationCloud/page-zcyz?menu_id=' + this.class2)
      }
      else if (this.class1 == 3) {
        this.$router.push('/solicitationCloud/page-ysxz?menu_id=' + this.class2)
      }
      else if (this.class1 == 4) {
        this.$router.push('/solicitationCloud/page-jjxz?menu_id=' + this.class2)
      }
    },
    topHuodongBaoming() {
      if (this.class1 == null) {
        this.$message.warning('请选择要报名活动的服务');
        return
      }
      this.$router.push('/solicitationCloud/page-huodongbaoming?menu_id=' + this.class1 + '&name=' + this.class1_options[this.class1]['label'])
    },
    topRuzhuShenqing() {
      if (this.class1 == null) {
        this.$message.warning('请选择要申请入驻的服务');
        return
      }
      if (this.class1 == 0) {
        this.$router.push('/solicitationCloud/page-zyys?menu_id=' + 'ruzhushenqing')
      } else if (this.class1 == 1) {
        this.$router.push('/solicitationCloud/page-zsyz?menu_id=' + 'ruzhushenqing')
      }
      else if (this.class1 == 2) {
        this.$message.warning('该服务尚未开通入驻功能');
        return
      }
      else if (this.class1 == 3) {
        this.$router.push('/solicitationCloud/page-ysxz?menu_id=' + 'ruzhushenqing')
      }
      else if (this.class1 == 4) {
        this.$router.push('/solicitationCloud/page-jjxz?menu_id=' + 'ruzhushenqing')
      }
    },
    getIndexInfo() {
      this.$api.solicitationCloud.index().then((res) => {
        const data = res.data.data
        if (data) {
          this.bannerList = data.bannerList
          this.announcementList = data.announcementList
          this.institutesMap = data.institutesMap
          this.businessMap = data.businessMap
          this.expertTownMap = data.expertTownMap
          this.fundTownMap = data.fundTownMap
          this.talentMap = data.talentMap
          this.talentMap.talentPolicy = this.talentMap.talentPolicy.length > 7 ? this.talentMap.talentPolicy.splice(0, 7) : this.talentMap.talentPolicy
        }
      })
    },
    goTzgg(id) {
      if (id) {
        this.$router.push({ path: '/solicitationCloud/page-tzgg/articledetail', query: { id: id, 'detailType': 'soli-notice' } })
      }
      else {
        this.$router.push('/solicitationCloud/page-tzgg')
      }
    },
    // 招院引所
    selectZyysMenu(id) {
      this.menu1_id = id
      if (id == 'ruzhushenqing') {
        this.selectZyysMore()
      }
    },
    selectZyysMore() {
      this.$router.push('/solicitationCloud/page-zyys?menu_id=' + this.menu1_id)
    },
    detailZyys(item, type) {
      if (type == 'ruzhuyuansuo') {
        this.$router.push({ path: '/solicitationCloud/page-zyys/yuansuoDetail', query: { id: item.id, 'detailType': 'soli-yuansuo' } })
      }
      else if (type == 'zhaoyinzhengce') {
        this.$router.push({ path: '/solicitationCloud/page-zyys/articledetail', query: { id: item.id, 'detailType': 'soli-policy' } })
      }
    },
    goZyysArticles(item) {
      this.$router.push({ path: '/solicitationCloud/page-zyys/articledetail', query: { id: item.id, 'detailType': 'soli-policy' } })
    },
    // 招商引资
    selectZsyzMenu(id) {
      this.menu2_id = id
      if (id == 'ruzhushenqing') {
        this.selectZsyzMore()
      }
    },
    selectZsyzMore() {
      this.$router.push('/solicitationCloud/page-zsyz?menu_id=' + this.menu2_id)
    },
    detailZsyz(item, type) {
      if (type == 'ruzhuqiye') {
        this.$router.push({ path: '/solicitationCloud/page-zsyz/detail-ruzhuqiye', query: { id: item.id } })
      }
      else if (type == 'yuanquxinxi') {
        this.$router.push({ path: '/solicitationCloud/page-zsyz/detail-yuanquxinxi', query: { id: item.id } })
      }
      else if (type == 'changfangzushou') {
        this.$router.push({ path: '/solicitationCloud/page-zsyz/detail-changfang', query: { id: item.id } })
      }
      else if (type == 'louyuzushou') {
        this.$router.push({ path: '/solicitationCloud/page-zsyz/detail-louyuzushou', query: { id: item.id } })
      }
      else if (type == 'zhaoyinanli') {
        this.$router.push({ path: '/solicitationCloud/page-zsyz/anliDetail', query: { id: item.id, 'detailType': 'soli-anli' } })
      }
      else if (type == 'zhaoyinzhengce') {
        this.$router.push({ path: '/solicitationCloud/page-zsyz/articledetail', query: { id: item.id, 'detailType': 'soli-zhaoyinzhengce' } })
      }
    },
    // 招才引智
    selectZcyzMenu(id) {
      this.menu3_id = id
    },
    selectZcyzMore() {
      this.$router.push('/solicitationCloud/page-zcyz?menu_id=' + this.menu3_id)
    },
    detailZcyz(item, type) {
      if (type == 'zhengcezhichi') {
        this.$router.push({ path: '/solicitationCloud/page-zcyz/articledetail', query: { id: item.id, 'detailType': 'soli-zhaocaizhengce' } })
      }
      else if (type == 'rencaizhaopin') {
        this.$router.push('/solicitationCloud/page-zcyz/detail-rencaizhaopin?id=' + item.id)
      }
    },
    // 院士专家小镇
    selectYsxzMenu(id) {
      this.menu4_id = id
      if (id == 'ruzhushenqing') {
        this.selectYsxzMore()
      }
    },
    selectYsxzMore() {
      this.$router.push('/solicitationCloud/page-ysxz?menu_id=' + this.menu4_id)
    },
    detailYsxz(item, type) {
      if (type == 'yuanshijieshao') {
        this.$router.push('/solicitationCloud/page-ysxz/detail-yuanshizhuanjia?id=' + item.id)
      }
      else if (type == 'yuanshijiangtang') {
        this.$router.push('/solicitationCloud/page-ysxz/detail-yuanshijiangtang?id=' + item.id)
      }
      else if (type == 'zhengcezhichi') {
        this.$router.push({ path: '/solicitationCloud/page-ysxz/articledetail', query: { id: item.id, 'detailType': 'soli-ysxzpolicy' } })
      }
    },
    // 基金小镇
    selectJjxzMenu(id) {
      console.log('基金小镇 = ', id)
      this.menu5_id = id
      if (id == 'ruzhushenqing') {
        this.selectJjxzMore()
      }
    },
    selectJjxzMore() {
      this.$router.push('/solicitationCloud/page-jjxz?menu_id=' + this.menu5_id)
    },
    detailJjxz(item, type) {
      if (type == 'jinrongjigou') {
        this.$router.push('/solicitationCloud/page-jjxz/detail-jinrongjigou?id=' + item.id)
      }
      else if (type == 'luyanhuodong') {
        this.$router.push('/solicitationCloud/page-jjxz/detail-huodong?id=' + item.id)
      }
      else if (type == 'zhengcezhichi') {
        this.$router.push({ path: '/solicitationCloud/page-jjxz/articledetail', query: { id: item.id, 'detailType': 'soli-jjxzpolicy' } })
      }
    },
    yuansuoDetail(item) {
      this.$router.push('/solicitationCloud/page-zyys/yuansuoDetail')
    },
    qiyeDetailClick(item) {
      this.$router.push('/solicitationCloud/page-zsyz/detail-ruzhuqiye')
    },
    goZhaocaiArticles(item) {
      this.$router.push('/solicitationCloud/page-zcyz/articledetail')
    },
    yuanshiDetail(item) {
      this.$router.push('/solicitationCloud/page-ysxz/detail-yuanshizhuanjia?id=' + item.id)
    },

  }
}
</script>

<style scoped lang="less">
.horizontal_1_1_wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .item {
    flex: 1;
    flex-shrink: 0;
    min-width: 10px;
  }

  .divider-h {
    min-width: 47px;
  }

  .divider-v {
    min-height: 47px;
  }
}

.custom-yellow-button {
  height: 40px;
  border-radius: 4px;
  background-color: #f39800;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
}

.horizontal_1_3_wrapper {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  .left {
    flex: 1;
    flex-shrink: 0;
    min-width: 10px;
  }

  .right {
    flex: 3;
    flex-shrink: 0;
    min-width: 10px;

    // display: flex;
    // flex-direction: column;
    .content {
      display: flex;
      flex-direction: row;

      .single-line {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .divider-h {
    width: 3px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 0 40px;
  }
}

.sub-section-title {
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 0 20px;

  .more {
    cursor: pointer;
  }
}

.xiaozhen-title {
  margin: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 700;
  font-size: 110%;
}

.xiaozhen-text {
  flex: 1;
  // text-overflow: ellipsis;;
  margin: 5px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}
</style>
